<template>
  <div>
    <slot>
      {{ serviceTypeLabel }}
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    serviceType: {
      type: String,
      default: ''
    }
  },
  computed: {
    serviceTypeLabel: function() {
      if (this.serviceType === 'URL') {
        return 'Url';
      }

      if (this.serviceType === 'WS') {
        return 'Workspace';
      }

      if (this.serviceType === 'DOC') {
        return 'Documental';
      }

      if (this.serviceType === 'TICKET') {
        return 'Ticketing';
      }

      if (this.serviceType === 'ALERT EX-POST') {
        return 'Alert Ex-Post';
      }

      if (this.serviceType === 'REPORTS') {
        return 'Reports';
      }

      if (this.serviceType === 'REPORT-HUB') {
        return 'Report Hub';
      }

      return this.serviceType;
    }
  }
};
</script>
